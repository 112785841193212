import React, { useState } from 'react';

import authService from '../services/authService';

const Feature = ({ title, description }) => (
  <div className="bg-white/10 p-6 rounded-lg shadow-lg">
    <h2 className="text-xl font-semibold mb-2">{title}</h2>
    <p>{description}</p>
  </div>
);

const LoginForm = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin(username, password);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <input
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="Username"
        required
        className="w-full p-2 rounded bg-white/20 placeholder-gray-400"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
        required
        className="w-full p-2 rounded bg-white/20 placeholder-gray-400"
      />
      <button
        type="submit"
        className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Login
      </button>
    </form>
  );
};

const HomePage = (props) => {
  // eslint-disable-next-line
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // eslint-disable-next-line
  const [username, setUsername] = useState('');

  React.useEffect(() => {
    const checkAuth = async () => {
      const authenticated = await authService.isAuthenticated();
      setIsLoggedIn(authenticated);
    };
    checkAuth();
  }, []);

  const handleLogin = async (username, password) => {
    try {
      const success = await authService.login(username, password);
      if (success) {
        setIsLoggedIn(true);
        setUsername(username);

        props.userIsLoggedIn();
      } else {
        alert('Invalid username or password');
      }
    } catch (error) {
      console.error("Login failed:", error);
      alert('An error occurred while logging in.');
    }
  };

  /*
  const handleLogout = () => {
    authService.logout();
    setIsLoggedIn(false);
    setUsername('');
  };
  */

  

  return (
    <div className="grid md:grid-cols-2 gap-8">
      <div className="space-y-6">
        <Feature
          title="Access Control"
          description="Securely manage who can access your APIs."
        />
        <Feature
          title="Billing Integration"
          description="Automate billing with precise usage metrics."
        />
        <Feature
          title="Usage Tracking"
          description="Monitor how your APIs are being used in real-time."
        />
        <Feature
          title="Health Checks"
          description="Ensure your APIs are healthy with automated checks."
        />
      </div>
      <div className="bg-white/10 p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold mb-4">Welcome to Táillí</h1>
        <p className="mb-6">API Access control, usage, health, and billing</p>
        <LoginForm onLogin={handleLogin} />
      </div>
    </div>
  );
};

export default HomePage;