import React, { useState, createContext, useCallback, useEffect } from 'react';
import { Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import HomePage from './components/HomePage';
import InvitePage from './components/InvitePage';
import AdminDashboard from './components/AdminDashboard';
import ProductPlanManagement from './components/ProductPlanManagement';
import ApiKeyIssuanceScreen from './components/apiKeyIssuanceScreen';
import NavBar from './components/NavBar';
import TeamSelector from './components/TeamSelector';
import authService from './services/authService';
import userService from './services/userService';

import './index.css';
import TailliDashboard from './dashboard';

export const AppContext = createContext(null);

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [userTeams, setUserTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const navigate = useNavigate();

  const handleTeamChange = useCallback((team) => {
    console.log('Team changed:', team);
    setSelectedTeam(team);

    // It is possible that team is not defined, so we need to check for that
    // - Set role if we have one
    // - If not, set role to '-'
    if (team && team.role) {
      setUserRole(team.role);
    } else {
      setUserRole('-');
    }

    localStorage.setItem('selectedTeam', JSON.stringify(team));
  }, []);

  const handleLogout = useCallback(() => {
    authService.clearTokens();
    setIsLoggedIn(false);
    setUser(null);
    setUserTeams([]);
    setSelectedTeam(null);
    setUserRole(null);
    localStorage.removeItem('selectedTeam');
    navigate('/');
  }, [navigate]);

  const fetchUserData = async () => {
    try {
      const token = authService.getToken();
      if (!token) {
        setIsLoggedIn(false);
        return;
      }

      // Fetch user details using the new endpoint
      const userDetails = await userService.getUserDetails(token);
      setUser(userDetails);
      setUserTeams(userDetails.teams);
      setIsLoggedIn(true);

      const storedTeam = localStorage.getItem('selectedTeam');
      if (storedTeam) {
        const parsedTeam = JSON.parse(storedTeam);
        setSelectedTeam(parsedTeam);
        setUserRole(parsedTeam.role);
      } else if (userDetails.teams.length > 0) {
        setSelectedTeam(userDetails.teams[0]);
        setUserRole(userDetails.teams[0].role);
        localStorage.setItem('selectedTeam', JSON.stringify(userDetails.teams[0]));
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      handleLogout();
    } 
  };

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line
  }, []); 

  return (
    <AppContext.Provider value={{ user, selectedTeam, userRole, setSelectedTeam }}>
      <div className="min-h-screen bg-gradient-to-br from-purple-700 via-indigo-800 to-blue-900 text-white">
        <div className="container mx-auto px-4 py-8">
          <header className="flex justify-between items-center mb-8">
            <div className="flex items-center space-x-4">
              <img src="/tailli_logo.png" alt="Táillí Logo" className="h-16" />
              <div className="text-3xl font-bold">Táillí</div>
            </div>
            {isLoggedIn && user && (
              <div className="flex items-center space-x-4">
                <TeamSelector 
                  teams={userTeams}
                  selectedTeam={selectedTeam}
                  onTeamChange={handleTeamChange}
                />
                <div className="text-sm">
                  Logged in as: {user.username} ({userRole || 'No role'})
                </div>
                <button
                  onClick={handleLogout}
                  className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                  Logout
                </button>
              </div>
            )}
          </header>

          <main>
            {isLoggedIn && <NavBar />}
            <Routes>
              <Route exact path="/" element= 
                  {isLoggedIn ? <Navigate to="/dashboard" /> : 
                  <HomePage userIsLoggedIn={() => fetchUserData()} />}
              />
              <Route path="/dashboard" element={<TailliDashboard />} />
              <Route path="/invite/:teamId/:inviteToken" element={<InvitePage />} />
              <Route path="/admin" element={<AdminDashboard />} />
              <Route path="/manage-products" element={<ProductPlanManagement />} />
              <Route path="/manage-keys" element={<ApiKeyIssuanceScreen />} />
            </Routes>
          </main>

          <footer className="mt-12 text-center text-sm">
            © 2024 Latent Search
          </footer>
        </div>
      </div>
    </AppContext.Provider>
  );
}

export default App;