import { useState, useEffect, useContext, useCallback } from 'react';
import { AppContext } from '../App';
import authService from '../services/authService';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "./ui/AlertDialog";
import { CustomerEditDialog } from './ui/CustomerEditDialog';
import { Copy, ToggleLeft, ToggleRight, Edit2, Trash2,Eye, EyeOff } from "lucide-react";
import config from '../config';

export default function ApiKeyManagementScreen() {
  const { selectedTeam } = useContext(AppContext);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isAddingCustomer, setIsAddingCustomer] = useState(false);
  const [customerForm, setCustomerForm] = useState({ displayName: '', email: '' });
  const [apiKeys, setApiKeys] = useState([]);
  const [keyForm, setKeyForm] = useState({ nickname: '', ttl: 24 });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [editingKey, setEditingKey] = useState(null);
  const [editingNickname, setEditingNickname] = useState('');

  const [teamProducts, setTeamProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  
  const [deleteKey, setDeleteKey] = useState(null);
  const [revealedKeys, setRevealedKeys] = useState(new Set());

  const [editingCustomer, setEditingCustomer] = useState(null);

  const maskApiKey = (key) => {
    if (revealedKeys.has(key)) return key;
    return `${key.slice(0, 4)}...${key.slice(-4)}`;
  };

  // Add these new fetch functions
  // Add a new function to fetch products owned by the team
  const fetchTeamProducts = useCallback(async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/GetOwnedProducts?teamId=${selectedTeam.teamId}`, {
        headers: { "Authorization": `${authService.getToken()}` }
      });
      if (!response.ok) throw new Error('Failed to fetch products');
      const data = await response.json();
      setTeamProducts(data);
      if (data.length === 1) {
        setSelectedProduct(data[0]);
        fetchPlans(data[0].RowKey);
      }
    } catch (error) {
      setError('Failed to load products');
    }
  }, [selectedTeam]);

  // Add this effect to fetch products when team is selected
  useEffect(() => {
    if (selectedTeam?.teamId) {
      fetchTeamProducts();
    }
  }, [selectedTeam, fetchTeamProducts]);


  
  const fetchPlans = async (productId) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/GetPlans?productId=${productId}`, {
        headers: { "Authorization": `${authService.getToken()}` }
      });
      if (!response.ok) throw new Error('Failed to fetch plans');
      const data = await response.json();
      setPlans(data);
      if (data.length === 1) {
        setSelectedPlan(data[0]);
      }
    } catch (error) {
      setError('Failed to load plans');
    }
  };

  

  const handleUpdateCustomer = async (customerGuid, updates) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/UpdateCustomer`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${authService.getToken()}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          customerId: customerGuid,
          ...updates
        })
      });
      
      if (!response.ok) throw new Error('Failed to update customer');
      setSuccess('Customer updated successfully');
      fetchCustomers();
    } catch (error) {
      setError('Failed to update customer');
    }
  };

  const handleDeleteCustomer = async (customerGuid) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/DeleteCustomer?customerId=${customerGuid}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${authService.getToken()}`
        }
      });
      
      if (!response.ok) throw new Error('Failed to delete customer');
      setSuccess('Customer deleted successfully');
      setSelectedCustomer(null);
      setEditingCustomer(null);
      fetchCustomers();
    } catch (error) {
      setError('Failed to delete customer');
    }
  };
  
  const handleCustomerSelect = (customerGuid) => {
    const customer = customers.find(c => c.CustomerGUID === customerGuid);
    setSelectedCustomer(customer);
    if (customer) {
      fetchCustomerKeys(customer.CustomerGUID);
    }
  };
  
  // Update handleGenerateKey to include product and plan
  const handleGenerateKey = async () => {
    try {
      if (!selectedCustomer || !selectedProduct || !selectedPlan || !keyForm.nickname) {
        setError('Please fill in all required fields');
        return;
      }
  
      const response = await fetch(`${config.apiBaseUrl}/api/CreateApiKey`, {
        method: 'POST',
        headers: {
          'Authorization': `${authService.getToken()}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          customerGuid: selectedCustomer.CustomerGUID,
          productId: selectedProduct.RowKey,
          planId: selectedPlan.RowKey,
          ttl: keyForm.ttl,
          nickname: keyForm.nickname
        })
      });
      
      if (!response.ok) throw new Error('Failed to generate key');
      
      const newKey = await response.json();
      setSuccess('API key generated successfully');
      setApiKeys([...apiKeys, newKey]);
      setKeyForm({ nickname: '', ttl: 24 });
    } catch (error) {
      setError('Failed to generate API key');
    }
  };

    // Add delete handler
    const handleDeleteKey = async (keyId) => {
      try {
        const response = await fetch(`${config.apiBaseUrl}/api/DeleteApiKey?keyId=${keyId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${authService.getToken()}`
          }
        });
        
        if (!response.ok) throw new Error('Failed to delete key');
        
        setApiKeys(apiKeys.filter(key => key.RowKey !== keyId));
        setSuccess('API key deleted successfully');
      } catch (error) {
        setError('Failed to delete API key');
      }
      setDeleteKey(null);
    };

    
  const handleUpdateNickname = async (keyId, newNickname) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/UpdateApiKey`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${authService.getToken()}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          keyId,
          nickname: newNickname
        })
      });
      
      if (!response.ok) throw new Error('Failed to update nickname');
      
      setApiKeys(apiKeys.map(key => 
        key.RowKey === keyId ? { ...key, Nickname: newNickname } : key
      ));
      setSuccess('Nickname updated successfully');
      setEditingKey(null);
    } catch (error) {
      setError('Failed to update nickname');
    }
  };
  
  const fetchCustomers = useCallback(async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}${config.endpoints.customerList}?teamId=${selectedTeam.teamId}`, {
        headers: { "Authorization": `${authService.getToken()}`, }
        
      });
      if (!response.ok) throw new Error('Failed to fetch customers');
      const data = await response.json();
      setCustomers(data);
    } catch (error) {
      setError('Failed to load customers');
    }
  }, [selectedTeam]);

  useEffect(() => {
    if (selectedTeam?.teamId) {
      fetchCustomers();
    }
  }, [selectedTeam, fetchCustomers]);

  useEffect(() => {
    if (selectedTeam?.teamId) {
      fetchCustomers();
    }
  }, [selectedTeam, fetchCustomers]);

  const fetchCustomerKeys = async (customerGuid) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/GetCustomerKeys?customer_guid=${customerGuid}`, {
        headers: { 'Authorization': `Bearer ${authService.getToken()}` }
      });
      if (!response.ok) throw new Error('Failed to fetch API keys');
      const data = await response.json();
      setApiKeys(data);
    } catch (error) {
      setError('Failed to load API keys');
    }
  };


  const handleCreateCustomer = async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/CreateCustomer`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authService.getToken()}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...customerForm,
          teamId: selectedTeam.teamId
        })
      });
      
      if (!response.ok) throw new Error('Failed to create customer');
      
      setSuccess('Customer created successfully');
      setIsAddingCustomer(false);
      setCustomerForm({ displayName: '', email: '' });
      fetchCustomers();
    } catch (error) {
      setError('Failed to create customer');
    }
  };

  const handleToggleKey = async (keyId, currentState) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/ToggleApiKey`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authService.getToken()}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          keyId,
          enabled: !currentState
        })
      });
      
      if (!response.ok) throw new Error('Failed to toggle key');
      
      fetchCustomerKeys(selectedCustomer.CustomerGUID);
      setSuccess(`API key ${currentState ? 'disabled' : 'enabled'} successfully`);
    } catch (error) {
      setError('Failed to toggle API key');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-700 via-indigo-800 to-blue-900 text-white p-8">
      <div className="max-w-6xl mx-auto">
        <header className="flex justify-between items-center mb-12">
          <h1 className="text-4xl font-bold tracking-tight">API Key Management</h1>
          <button
            onClick={() => setIsAddingCustomer(true)}
            className="flex items-center gap-2 bg-white/10 hover:bg-white/20 px-4 py-2 rounded-lg"
          >
            + New Customer
          </button>
        </header>

        {/* Alerts */}
        {error && (
          <div className="mb-6 p-4 bg-red-500/10 text-red-200 rounded-lg">
            {error}
          </div>
        )}
        {success && (
          <div className="mb-6 p-4 bg-green-500/10 text-green-200 rounded-lg">
            {success}
          </div>
        )}

        {/* Customer Selection */}
        <div className="bg-white/5 rounded-lg p-6 mb-8">
 <h2 className="text-xl font-semibold mb-4">Select Customer</h2>
 <div className="flex gap-2">
   <select
     className="flex-1 bg-white/10 p-3 rounded-lg border border-white/20 text-white [&>option]:bg-gray-800 [&>option]:text-white"
     value={selectedCustomer?.CustomerGUID || ''}
     onChange={(e) => handleCustomerSelect(e.target.value)}
   >
     <option value="">Choose a customer...</option>
     {customers.map(customer => (
       <option key={customer.CustomerGUID} value={customer.CustomerGUID}>
         {customer.DisplayName}
       </option>
     ))}
   </select>
   <button
     onClick={() => selectedCustomer && setEditingCustomer(selectedCustomer)}
     className="p-3 hover:bg-white/10 rounded-lg border border-white/20 disabled:opacity-50"
     disabled={!selectedCustomer}
     title="Edit customer"
   >
     <Edit2 size={20} />
   </button>
 </div>
</div>
      
        {/* New Customer Form */}
        {isAddingCustomer && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
            <div className="bg-gray-800 p-6 rounded-lg w-full max-w-md">
              <h2 className="text-xl font-semibold mb-4">New Customer</h2>
              <div className="space-y-4">
                <input
                  type="text"
                  placeholder="Display Name"
                  className="w-full p-2 bg-white/10 rounded border border-white/20"
                  value={customerForm.displayName}
                  onChange={(e) => setCustomerForm({ ...customerForm, displayName: e.target.value })}
                />
                <input
                  type="email"
                  placeholder="Email"
                  className="w-full p-2 bg-white/10 rounded border border-white/20"
                  value={customerForm.email}
                  onChange={(e) => setCustomerForm({ ...customerForm, email: e.target.value })}
                />
                <div className="flex justify-end gap-2">
                  <button
                    onClick={() => setIsAddingCustomer(false)}
                    className="px-4 py-2 bg-white/10 rounded"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleCreateCustomer}
                    className="px-4 py-2 bg-blue-500 rounded"
                    disabled={!customerForm.displayName || !customerForm.email}
                  >
                    Create Customer
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {editingCustomer && (
          <CustomerEditDialog
            customer={editingCustomer}
            onClose={() => setEditingCustomer(null)}
            onUpdate={handleUpdateCustomer}
            onDelete={handleDeleteCustomer}
          />
        )}

            {/* API Keys Section */}
            {selectedCustomer && (
              <div className="bg-white/5 rounded-lg p-6">
                <h2 className="text-xl font-semibold mb-6">API Keys for {selectedCustomer.DisplayName}</h2>
                
                {/* Existing Keys */}
                <div className="space-y-4 mb-8">
                {apiKeys.map(key => (
            <div key={key.RowKey} className="bg-white/10 p-4 rounded-lg">
              <div className="flex justify-between items-center">
                {/* ... key display ... */}
                <div className="flex-grow">
                  {editingKey === key.RowKey ? (
                    <div className="flex items-center gap-2">
                      <input
                        type="text"
                        className="p-1 bg-white/10 rounded border border-white/20 text-white"
                        value={editingNickname}
                        onChange={(e) => setEditingNickname(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleUpdateNickname(key.RowKey, editingNickname);
                          }
                        }}
                      />
                      <button
                        onClick={() => handleUpdateNickname(key.RowKey, editingNickname)}
                        className="px-2 py-1 bg-green-500/20 rounded hover:bg-green-500/30"
                      >
                        Save
                      </button>
                      <button
                        onClick={() => setEditingKey(null)}
                        className="px-2 py-1 bg-red-500/20 rounded hover:bg-red-500/30"
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <div>
                      <div className="flex items-center gap-2">
                        <p className="font-medium">{key.Nickname || 'Unnamed Key'}</p>
                      </div>
                      <p className="text-sm text-gray-300">Created: {new Date(key.CreatedAt).toLocaleDateString()}</p>
                      <p className="text-sm text-gray-300">Key: {maskApiKey(key.RowKey)}</p>
                    </div>

                    
                  )}
                </div>
                
                
                <div className="flex items-center gap-2">
                <button
                  onClick={() => setRevealedKeys(prev => {
                    const next = new Set(prev);
                    prev.has(key.RowKey) ? next.delete(key.RowKey) : next.add(key.RowKey);
                    return next;
                  })}
                  className="p-1 hover:bg-white/10 rounded"
                  title={revealedKeys.has(key.RowKey) ? "Hide key" : "Show key"}
                >
                  {revealedKeys.has(key.RowKey) ? <EyeOff size={18} /> : <Eye size={18} />}
                </button>

                  <button
                    onClick={() => navigator.clipboard.writeText(key.RowKey)}
                    className="p-2 hover:bg-white/10 rounded tooltip"
                    title="Copy API key"
                  >
                    <Copy size={18} />
                  </button>
                  <button
                    onClick={() => handleToggleKey(key.RowKey, key.Enabled)}
                    className={`p-2 rounded ${
                      key.Enabled 
                        ? 'bg-green-500/20 text-green-300 hover:bg-green-500/30' 
                        : 'bg-red-500/20 text-red-300 hover:bg-red-500/30'
                    }`}
                    title={key.Enabled ? 'Disable key' : 'Enable key'}
                  >
                    {key.Enabled ? <ToggleRight size={18} /> : <ToggleLeft size={18} />}
                  </button>
                  <button
                    onClick={() => setEditingKey(key.RowKey)}
                    className="p-2 hover:bg-blue-500/20 rounded text-blue-300"
                    title="Edit nickname"
                  >
                    <Edit2 size={18} />
                  </button>
                  <button
                    onClick={() => setDeleteKey(key)}
                    className="p-2 hover:bg-red-500/20 rounded text-red-300"
                    title="Delete key"
                  >
                    <Trash2 size={18} />
                  </button>
                </div>

                
              </div>
            </div>
          ))}

          {/* Delete Confirmation Dialog */}
          <AlertDialog open={!!deleteKey} onOpenChange={() => setDeleteKey(null)}>
            <AlertDialogContent className="bg-gray-800 border border-gray-700">
              <AlertDialogHeader>
                <AlertDialogTitle className="text-white">Delete API Key</AlertDialogTitle>
                <AlertDialogDescription className="text-gray-300">
                  Are you sure you want to delete this API key? This action cannot be undone.
                  {deleteKey && (
                    <div className="mt-2 p-2 bg-white/5 rounded">
                      <p className="font-medium">{deleteKey.Nickname || 'Unnamed Key'}</p>
                      <p className="text-sm">{deleteKey.RowKey}</p>
                    </div>
                  )}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel className="bg-gray-700 text-white hover:bg-gray-600">
                  Cancel
                </AlertDialogCancel>
                <AlertDialogAction 
                  className="bg-red-600 text-white hover:bg-red-500"
                  onClick={() => deleteKey && handleDeleteKey(deleteKey.RowKey)}
                >
                  Delete
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          
            </div>
            {/* New Key Form */}
            <div className="bg-white/10 p-4 rounded-lg">
              <h3 className="text-lg font-semibold mb-4">Generate New API Key</h3>
              <div className="space-y-4">
              <div>
                <label className="block text-sm mb-1">Product</label>
                <select
                  className="w-full p-2 bg-white/10 rounded border border-white/20 text-white [&>option]:bg-gray-800 [&>option]:text-white"
                  value={selectedProduct?.RowKey || ''}
                  onChange={(e) => {
                    const product = teamProducts.find(p => p.RowKey === e.target.value);
                    setSelectedProduct(product);
                    if (product) {
                      fetchPlans(product.RowKey);
                    }
                    setSelectedPlan(null);
                  }}
                >
                  <option value="">Select a product</option>
                  {teamProducts.map(product => (
                    <option key={product.RowKey} value={product.RowKey}>
                      {product.ProductName}
                    </option>
                  ))}
                </select>
              </div>

                {selectedProduct && (
                  <div>
                    <label className="block text-sm mb-1">Plan</label>
                    <select
                      className="w-full p-2 bg-white/10 rounded border border-white/20 text-white [&>option]:bg-gray-800 [&>option]:text-white"
                      value={selectedPlan?.RowKey || ''}
                      onChange={(e) => {
                        const plan = plans.find(p => p.id === e.target.value);
                        setSelectedPlan(plan);
                      }}
                    >
                      <option value="">Select a plan</option>
                      {plans.map(plan => (
                        <option key={plan.id} value={plan.id}>
                          {plan.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                <input
                  type="text"
                  placeholder="Key Nickname"
                  className="w-full p-2 bg-white/10 rounded border border-white/20"
                  value={keyForm.nickname}
                  onChange={(e) => setKeyForm({ ...keyForm, nickname: e.target.value })}
                />
                
                <div>
                  <label className="block text-sm mb-1">Cache TTL (hours)</label>
                  <input
                    type="number"
                    className="w-full p-2 bg-white/10 rounded border border-white/20"
                    value={keyForm.ttl}
                    onChange={(e) => setKeyForm({ ...keyForm, ttl: parseInt(e.target.value) })}
                    min="1"
                  />
                </div>

                <button
                  onClick={handleGenerateKey}
                  disabled={!selectedProduct || !selectedPlan || !keyForm.nickname}
                  className="w-full py-2 bg-blue-500 rounded disabled:bg-blue-500/50"
                >
                  Generate API Key
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}