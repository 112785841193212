import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import config from '../config';

const InvitePage = () => {
  const { teamId, inviteToken } = useParams();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleJoinTeam = async (e) => {
    e.preventDefault();
    if (!email || !username || !password) {
      setMessage("Please fill in all fields.");
      return;
    }

    try {
      const response = await fetch(`${config.apiBaseUrl}/api/JoinTeam`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ teamId, inviteToken, email, username, password }),
      });

      if (response.ok) {
        setMessage("You have successfully joined the team! Please log in.");
      } else {
        const errorData = await response.json();
        setMessage(errorData.message || "Error joining the team.");
      }
    } catch (error) {
      console.error("Error joining team:", error);
      setMessage("An error occurred. Please try again.");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-700 via-indigo-800 to-blue-900 text-white flex justify-center items-center">
      <div className="bg-white p-6 rounded shadow-lg w-96">
        <h1 className="text-2xl font-bold mb-4 text-gray-800">Join the Team</h1>
        <form onSubmit={handleJoinTeam}>
          <input
            type="email"
            placeholder="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 rounded bg-gray-200 mb-4 text-gray-800"
            required
          />
          <input
            type="text"
            placeholder="Choose a Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full p-2 rounded bg-gray-200 mb-4 text-gray-800"
            required
          />
          <input
            type="password"
            placeholder="Choose a Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 rounded bg-gray-200 mb-4 text-gray-800"
            required
          />
          <button type="submit" className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 rounded">
            Join Team
          </button>
        </form>
        {message && <p className="mt-4 text-center text-gray-800">{message}</p>}
      </div>
    </div>
  );
};

export default InvitePage;