import authService from './authService';
import config from '../config';

const userService = {
  getUserDetails: async (token) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/GetUserDetails`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user details');
      }

      return await response.json();
    } catch (error) {
      console.error('Error in getUserDetails:', error);
      throw error;
    }
  },

  async getUserTeams() {
    if (!authService.isAuthenticated()) {
      throw new Error('User is not authenticated');
    }

    try {
      const response = await fetch(`${config.apiBaseUrl}/api/GetUserTeams`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authService.getToken()}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 401) {
        authService.removeToken(); // Clear invalid token
        throw new Error('Authentication token is invalid or expired');
      }

      if (!response.ok) {
        throw new Error('Failed to fetch user teams');
      }

      const teamsData = await response.json();
      return teamsData.map(team => ({
        teamId: team.PartitionKey,
        teamName: team.TeamName,
        role: team.Role
      }));
    } catch (error) {
      console.error('Error fetching user teams:', error);
      throw error;
    }
  }
};

export default userService;