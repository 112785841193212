import { useState } from 'react';
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription, AlertDialogFooter, AlertDialogCancel, AlertDialogAction } from "./AlertDialog";
import { Trash2, X } from "lucide-react";

export function CustomerEditDialog({ customer, onClose, onUpdate, onDelete }) {
  const [form, setForm] = useState({
    displayName: customer.DisplayName,
    email: customer.Email
  });
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const handleSubmit = async () => {
    await onUpdate(customer.CustomerGUID, form);
    onClose();
  };

  return (
    <AlertDialog open={true} onOpenChange={onClose}>
      <AlertDialogContent className="bg-gray-800 border border-gray-700">
        <AlertDialogHeader>
          <div className="flex justify-between items-center">
            <AlertDialogTitle className="text-white text-xl">Edit Customer</AlertDialogTitle>
            <button onClick={onClose} className="text-gray-400 hover:text-white">
              <X size={20} />
            </button>
          </div>
        </AlertDialogHeader>

        <div className="space-y-4 py-4">
          <div>
            <label className="text-sm text-gray-300 mb-1 block">Display Name</label>
            <input
              type="text"
              className="w-full p-2 bg-white/10 rounded border border-white/20 text-white"
              value={form.displayName}
              onChange={(e) => setForm({ ...form, displayName: e.target.value })}
            />
          </div>
          <div>
            <label className="text-sm text-gray-300 mb-1 block">Email</label>
            <input
              type="email"
              className="w-full p-2 bg-white/10 rounded border border-white/20 text-white"
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
            />
          </div>
        </div>

        <AlertDialogFooter className="flex justify-between">
          <button
            onClick={() => setDeleteConfirm(true)}
            className="flex items-center gap-2 px-4 py-2 bg-red-500/20 text-red-300 rounded hover:bg-red-500/30"
          >
            <Trash2 size={16} />
            Delete Customer
          </button>
          <div className="flex gap-2">
            <AlertDialogCancel className="bg-gray-700 text-white hover:bg-gray-600">
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              className="bg-blue-600 text-white hover:bg-blue-500"
              onClick={handleSubmit}
              disabled={!form.displayName || !form.email}
            >
              Save Changes
            </AlertDialogAction>
          </div>
        </AlertDialogFooter>
      </AlertDialogContent>

      {/* Delete Confirmation Dialog */}
      <AlertDialog open={deleteConfirm} onOpenChange={setDeleteConfirm}>
        <AlertDialogContent className="bg-gray-800 border border-gray-700">
          <AlertDialogHeader>
            <AlertDialogTitle className="text-white">Delete Customer</AlertDialogTitle>
            <AlertDialogDescription className="text-gray-300">
              Are you sure you want to delete this customer? This will also delete all their API keys.
              <div className="mt-2 p-2 bg-white/5 rounded">
                <p className="font-medium">{customer.DisplayName}</p>
                <p className="text-sm">{customer.Email}</p>
              </div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel className="bg-gray-700 text-white hover:bg-gray-600">
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              className="bg-red-600 text-white hover:bg-red-500"
              onClick={() => onDelete(customer.CustomerGUID)}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </AlertDialog>
  );
}