import React from 'react';
import config from '../config';
import authService from '../services/authService';

const DeleteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M3 6h18"></path>
    <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
    <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
    <line x1="10" y1="11" x2="10" y2="17"></line>
    <line x1="14" y1="11" x2="14" y2="17"></line>
  </svg>
);

const OutstandingInvites = ({ invites, teamId, onInviteDeleted }) => {
  const handleDeleteInvite = async (inviteToken) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/DeleteInvite?teamId=${teamId}&inviteToken=${inviteToken}`, {
        method: 'GET',
        headers: {
          ...authService.getAuthHeaders(),
        },
      });

      if (response.ok) {
        onInviteDeleted(inviteToken);
      } else {
        console.error('Failed to delete invite');
        // Optionally, you can show an error message to the user
      }
    } catch (error) {
      console.error('Error deleting invite:', error);
      // Optionally, you can show an error message to the user
    }
  };

  return (
    <div className="mt-4">
      {invites.length === 0 ? (
        <p>No outstanding invites.</p>
      ) : (
        <ul className="divide-y divide-gray-200">
          {invites.map((invite) => (
            <li key={invite.InviteToken} className="py-4 flex justify-between items-center">
              <div>
                <p className="font-semibold">{invite.InviteeEmail}</p>
                <p className="text-sm text-gray-500">Invited by: {invite.InviterEmail}</p>
                <p className="text-sm text-gray-500">Created: {new Date(invite.CreatedAt).toLocaleString()}</p>
                <p className="text-sm text-gray-500">Status: {invite.Accepted ? 'Accepted' : 'Pending'}</p>
              </div>
              <button
                onClick={() => handleDeleteInvite(invite.InviteToken)}
                className="text-red-600 hover:text-red-800"
                title="Delete Invite"
              >
                <DeleteIcon />
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default OutstandingInvites;