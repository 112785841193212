import React, { useState, useEffect } from 'react';
import config from '../config';

const InviteLinkGenerator = ({ teamId }) => {
  const [inviteLink, setInviteLink] = useState("");
  const [inviteeEmail, setInviteeEmail] = useState("");
  const [role, setRole] = useState("User");
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    // Check if the email is valid and a role is selected
    const isEmailValid = /\S+@\S+\.\S+/.test(inviteeEmail);
    setIsFormValid(isEmailValid && role !== "");
  }, [inviteeEmail, role]);

  const generateInviteLink = async () => {
    if (!isFormValid) return;

    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/InviteUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        },
        body: JSON.stringify({ 
          teamId, 
          userEmail: inviteeEmail, 
          role: role,
          urlRoot: config.apiBaseUrl 
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setInviteLink(data.inviteLink);
      } else {
        const errorData = await response.json();
        console.error('Failed to generate invite link:', errorData.message);
        alert(`Failed to generate invite link: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error generating invite link:', error);
      alert('An error occurred while generating the invite link.');
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(inviteLink).then(() => {
      alert('Invite link copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  return (
    <div className="mb-4">
      <input
        type="email"
        value={inviteeEmail}
        onChange={(e) => setInviteeEmail(e.target.value)}
        placeholder="Enter invitee's email"
        className="bg-white p-2 w-full rounded mb-2 text-gray-800"
      />
      <select
        value={role}
        onChange={(e) => setRole(e.target.value)}
        className="bg-white p-2 w-full rounded mb-2 text-gray-800"
      >
        <option value="User">User</option>
        <option value="Admin">Admin</option>
      </select>
      <button 
        onClick={generateInviteLink} 
        className={`p-2 rounded ${isFormValid ? 'bg-blue-600 text-white' : 'bg-gray-400 text-gray-700 cursor-not-allowed'}`}
        disabled={!isFormValid}
      >
        Generate Invite Link
      </button>
      {inviteLink && (
        <div className="mt-4">
          <input 
            type="text" 
            value={inviteLink} 
            readOnly 
            className="bg-white p-2 w-full rounded mb-2 text-gray-800"
          />
          <button onClick={copyToClipboard} className="bg-green-600 text-white p-2 rounded">
            Copy Invite Link
          </button>
        </div>
      )}
    </div>
  );
};

export default InviteLinkGenerator;