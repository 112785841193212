import React, { useEffect } from 'react';

const EnhancedApiKeyDropdown = ({ apiKeys, selectedApiKey, onApiKeySelect }) => {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('API Key copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  useEffect(() => {
    // If there's only one API key and no key is currently selected, automatically select it
    if (apiKeys.length === 1 && !selectedApiKey) {
      onApiKeySelect(apiKeys[0].RowKey);
    }
  }, [apiKeys, selectedApiKey, onApiKeySelect]);

  return (
    <div className="relative">
      <select 
        value={selectedApiKey || ''}
        onChange={(e) => onApiKeySelect(e.target.value)}
        className="w-full bg-white text-purple-900 border-none p-2 rounded mb-8 pr-10"
      >
        {apiKeys.length > 1 && <option value="">Select API Key</option>}
        {apiKeys.map((key) => (
          <option key={key.RowKey} value={key.RowKey}>
            {key.ProductName} ({key.RowKey})
          </option>
        ))}
      </select>
      {selectedApiKey && (
        <button 
          onClick={() => copyToClipboard(selectedApiKey)}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-purple-500 hover:bg-purple-600 p-1 rounded"
          aria-label="Copy API Key"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-white">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
          </svg>
        </button>
      )}
    </div>
  );
};

export default EnhancedApiKeyDropdown;