import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import config from './config';
import { formatNumber } from './utils';
import EnhancedApiKeyDropdown from './EnhancedApiKeyDropdown';
import { AppContext } from './App';
import authService from './services/authService';

const TailliDashboard = () => {
  const { selectedTeam } = useContext(AppContext);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedCustomerName, setSelectedCustomerName] = useState('');
  const [customers, setCustomers] = useState([]);
  const [usageData, setUsageData] = useState([]);
  const [usagePeriod, setUsagePeriod] = useState('thisMonth');
  const [totalUnits, setTotalUnits] = useState(0);
  const [apiKeys, setApiKeys] = useState([]);
  const [selectedApiKey, setSelectedApiKey] = useState(null);
  const [selectedProductName, setSelectedProductName] = useState('');

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const fetchCustomers = useCallback(async () => {

    setCustomers([]);
    setSelectedCustomer(null);
    setApiKeys([]);
    setSelectedApiKey(null);

    if (!selectedTeam) {
      console.log('No team selected');
      return;
    }
    try {
      const response = await fetch(`${config.apiBaseUrl}${config.endpoints.customerList}?teamId=${selectedTeam.teamId}`, {
        method: "GET",
        headers: {
          "Authorization": `${authService.getToken()}`,
          "Content-Type": "application/json"
        }
      });
      const data = await response.json();
      console.log('Fetched customers:', data);
      setCustomers(data);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  }, [selectedTeam]);
  
  useEffect(() => {
    console.log('Selected team changed:', selectedTeam);
    fetchCustomers();
  }, [fetchCustomers, selectedTeam]);

  const fetchCustomerKeys = useCallback(async (customerGUID) => {
    const token = authService.getToken();
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/GetCustomerKeys?customer_guid=${customerGUID}`, {
        method: "GET",
        headers: {
          "Authorization": `${token}`,
          "Content-Type": "application/json"
        }
      });
      const data = await response.json();
      setApiKeys(data);
    } catch (error) {
      console.error('Error fetching customer keys:', error);
    }
  }, []);

  const fetchUsageData = useCallback(async (apikey) => {
    console.log('fetchUsageData', apikey);
    let endpoint;
    let urlParams = '';

    switch (usagePeriod) {
      case 'today':
        endpoint = config.endpoints.apiKeyUnitsUsedOnDate;
        break;
      case 'thisMonth':
        endpoint = config.endpoints.apiKeyUnitsThisMonth;
        break;
      case 'lastMonth':
        endpoint = config.endpoints.apiKeyUnitsUsedLastMonth;
        break;
      case 'custom':
        endpoint = config.endpoints.apiKeyUnitsForMonth;
        urlParams = `&year=${selectedYear}&month=${selectedMonth}`;
        break;
      default:
        endpoint = config.endpoints.apiKeyUnitsForMonth;
  }

  try {
    const response = await fetch(`${config.apiBaseUrl}${endpoint}?apikey=${apikey}${urlParams}`);
    const data = await response.json();

    // Set the total units from the response
    setTotalUnits(data.TotalUnits);

    let transformedData;
    if (data.UnitsPerDay) {
      transformedData = Object.keys(data.UnitsPerDay).map(date => ({
        date, // date is the key
        units: data.UnitsPerDay[date] // units is the value
      }));
    } else if (data.Date) {
      transformedData = [{
        date: data.Date,
        units: data.TotalUnits
      }];
    }

    setUsageData(transformedData);
  } catch (error) {
    console.error('Error fetching usage data:', error);
  }
}, [usagePeriod, selectedYear, selectedMonth]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);


  useEffect(() => {
    if (selectedCustomer) {
      const customer = customers.find(c => c.CustomerGUID === selectedCustomer);
      if (customer) {
        setSelectedCustomerName(customer.DisplayName);
      }

      setUsageData([]); // Clear usage data when switching customer
      setTotalUnits(0); // Reset total units when switching customer
      setSelectedYear(currentYear); // Reset to current year
      setSelectedMonth(currentMonth); // Reset to current month
      fetchCustomerKeys(selectedCustomer);
    }
    else {
      setApiKeys([]);
    }
  }, [selectedCustomer, customers, currentYear, currentMonth, fetchCustomerKeys]);

  useEffect(() => {
    if (selectedApiKey) {
      fetchUsageData(selectedApiKey);
    }
  }, [selectedApiKey, usagePeriod, selectedYear, selectedMonth, fetchUsageData]);  

  const handleApiKeySelection = (apiKey) => {
    setSelectedApiKey(apiKey);

    const apiKeyData = apiKeys.find(key => key.RowKey === apiKey);
    if (apiKeyData) {
      setSelectedProductName(apiKeyData.ProductName);
    }
  };

  const handleRefresh = useCallback(() => {
    if (selectedApiKey) {
      fetchUsageData(selectedApiKey);
    }
  }, [selectedApiKey, fetchUsageData]);

  const handleDownloadCSV = useCallback(() => {
    const customerName = selectedCustomerName; 
    const productName = selectedProductName; 
    const apiKeySuffix = selectedApiKey ? selectedApiKey.slice(-8) : '';
    const formattedMonthYear = `${selectedMonth}-${selectedYear}`;
    
    const fileName = `${customerName}_${productName}_${formattedMonthYear}_${apiKeySuffix}_.csv`;
    
    const csvRows = [
      ['Date', 'Units'],
      ...usageData.map(row => [row.date, row.units])
    ];

    const csvContent = `data:text/csv;charset=utf-8,${csvRows.map(e => e.join(",")).join("\n")}`;
    const link = document.createElement('a');
    link.setAttribute('href', encodeURI(csvContent));
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [selectedCustomerName, selectedProductName, selectedApiKey, selectedMonth, selectedYear, usageData]);

  const customerOptions = useMemo(() => (
    customers.map((customer) => (
      <option key={customer.CustomerGUID} value={customer.CustomerGUID}>
        {customer.DisplayName}
      </option>
    ))
  ), [customers]);

  // eslint-disable-next-line no-unused-vars
  const apiKeyOptions = useMemo(() => (
    apiKeys.map((key) => (
      <option key={key.RowKey} value={key.RowKey}>
        {key.ProductName} ({key.RowKey})
      </option>
    ))
  ), [apiKeys]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-700 via-indigo-800 to-blue-900 text-white p-8">
      <div className="max-w-6xl mx-auto">
        <header className="flex justify-between items-center mb-12">
          <h1 className="text-4xl font-bold tracking-tight">Consumption Dashboard</h1>
        </header>

        {selectedTeam ? (
          <>
          
            {/* Customer Selection Section */}
            <div className="flex flex-col space-y-4 mb-4">
              <select
                value={selectedCustomer || ''}
                onChange={(e) => setSelectedCustomer(e.target.value)}
                className="w-[200px] bg-white text-purple-900 border-none p-2 rounded"
              >
                <option value="">Select Customer</option>
                {customerOptions}
              </select>
            

            {/* API Key Dropdown Section */}
            {apiKeys.length > 0 && (
              <div className = "mb4">
                <EnhancedApiKeyDropdown
                  apiKeys={apiKeys}
                  selectedApiKey={selectedApiKey}
                  onApiKeySelect={handleApiKeySelection}
                />
              </div>
            )}
            </div>

            {/* Usage Data Section */}
            {selectedCustomer && (
              <div className="bg-white/5 p-4 rounded shadow">
                <h2 className="text-xl font-bold mb-4">API Usage</h2>
                
                <div className="flex mb-4">
                  {['today', 'thisMonth', 'lastMonth', 'custom'].map((period) => (
                    <button
                      key={period}
                      className={`p-2 mr-2 ${usagePeriod === period ? 'bg-blue-500 text-white' : 'bg-gray-200'} rounded`}
                      onClick={() => setUsagePeriod(period)}
                    >
                      {period.charAt(0).toUpperCase() + period.slice(1)}
                    </button>
                  ))}
                </div>

                {usagePeriod === 'custom' && (
                  <div className="flex mb-4">
                    {/* Year and Month Selectors */}
                  </div>
                )}

                <div className="flex gap-4 mb-8">
                  <button onClick={handleRefresh} className="bg-white/10 hover:bg-white/20 p-2 rounded">Refresh</button>
                  <button onClick={handleDownloadCSV} className="bg-white/10 hover:bg-white/20 p-2 rounded">Download CSV</button>
                </div>

                {totalUnits > 0 && (
                  <div className="mb-8">
                    <h3 className="text-2xl font-semibold">Total units for period: <span className="text-3xl font-bold text-yellow-400">{formatNumber(totalUnits)}</span></h3>
                  </div>
                )}

                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={usageData}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#ffffff20" />
                    <XAxis dataKey="date" stroke="#ffffff80" />
                    <YAxis stroke="#ffffff80" />
                    <Tooltip contentStyle={{ backgroundColor: '#ffffff20', borderColor: '#ffffff40' }} />
                    <Legend />
                    <Line type="monotone" dataKey="units" stroke="#6EE7B7" strokeWidth={3} dot={{ fill: '#6EE7B7', r: 6 }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )}
          </>
        ) : (
          <div className="text-center text-2xl mt-8">
            Please select a team to view the dashboard.
          </div>
        )}

        
      </div>
    </div>
  );
};

export default TailliDashboard;