import React from 'react';
import { NavLink } from 'react-router-dom';
import './NavBar.css'; // You can style it separately

const NavBar = () => {
  return (
    <nav className="nav-bar">
      <NavLink exact to="/" activeClassName="active">
        Consumption Dashboard
      </NavLink>
      <NavLink to="/manage-keys" activeClassName="active">
        Issue API Keys
      </NavLink>
      <NavLink to="/admin" activeClassName="active">
        Team Management
      </NavLink>
      <NavLink to="/manage-products" activeClassName="active">
        Manage Products
      </NavLink>
    </nav>
  );
};

export default NavBar;
