import config from '../config';

const AUTH_TOKEN_KEY = 'token';
const REFRESH_TOKEN_KEY = 'refreshToken';

const authService = {
  setTokens(token, refreshToken) {
    localStorage.setItem(AUTH_TOKEN_KEY, token);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  },

  getToken() {
    return localStorage.getItem(AUTH_TOKEN_KEY);
  },

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  },

  clearTokens() {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  },

  async isAuthenticated() {
    const token = this.getToken();
    if (!token) return false;

    try {
      const valid = await this.validateToken(token);
      if (!valid) {
        const success = await this.refreshToken();
        if (!success) {
          this.clearTokens();
        }
        return success;
      }
      return valid;
    } catch (error) {
      console.error('Error validating token:', error);
      return false;
    }
  },

  async validateToken(token) {
    const response = await fetch(`${config.apiBaseUrl}/api/validate-token`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.ok;
  },

  async refreshToken() {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) return false;

    try {
      const response = await fetch(`${config.apiBaseUrl}/api/refresh-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ refreshToken })
      });

      if (response.ok) {
        const { token, refreshToken: newRefreshToken } = await response.json();
        this.setTokens(token, newRefreshToken);
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return false;
    }
  },

  getAuthHeaders() {
    const token = this.getToken();
    return token ? { 'Authorization': `Bearer ${token}` } : {};
  },

  async login(username, password) {
    try {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);

      const response = await fetch(`${config.apiBaseUrl}/api/UserCredentialsValidation`, {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        const { token, refreshToken } = await response.json();
        this.setTokens(token, refreshToken);
        return true;
      }
      return false;
    } catch (error) {
      console.error('Login error:', error);
      return false;
    }
  },

  logout() {
    this.clearTokens();
  }
};

export default authService;