import React, { useState, useEffect } from 'react';
import config from '../config';

const TeamColleagues = ({ teamId }) => {
  const [colleagues, setColleagues] = useState([]);

  useEffect(() => {
    const fetchColleagues = async () => {
      if (!teamId) return;

      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${config.apiBaseUrl}/api/GetTeamMembers?teamId=${teamId}`, {
          method: 'GET',
          headers: {
            'Authorization': `${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setColleagues(data);
        } else {
          console.error('Failed to fetch team members');
        }
      } catch (error) {
        console.error('Error fetching team members:', error);
      }
    };

    fetchColleagues();
  }, [teamId]);

  return (
    <div className="mt-8 bg-white/5 p-6 rounded-lg shadow-lg">
      <h2 className="text-xl font-bold mb-4">Team Colleagues</h2>
      {colleagues.length > 0 ? (
        <ul className="space-y-2">
          {colleagues.map((colleague, index) => (
            <li key={index} className="bg-white/10 p-3 rounded">
              {colleague.username} <span className="font-semibold">{colleague.email}</span> - {colleague.role}
            </li>
          ))}
        </ul>
      ) : (
        <p>No team members found.</p>
      )}
    </div>
  );
};

export default TeamColleagues;