import React, { useState, useEffect } from 'react';
import config from '../config';

const ProductPlanManagement = () => {
  const [products, setProducts] = useState([]);
  const [newProductName, setNewProductName] = useState('');
  const [newPlanName, setNewPlanName] = useState('');
  const [newPlanType, setNewPlanType] = useState('consumption');
  const [newPlanQuota, setNewPlanQuota] = useState('');

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/GetProducts`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setProducts(data);
      } else {
        console.error('Failed to fetch products');
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleAddProduct = async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/AddProduct`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ name: newProductName }),
      });
      if (response.ok) {
        setNewProductName('');
        fetchProducts();
      } else {
        console.error('Failed to add product');
      }
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  const handleAddPlan = async (productId) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/AddPlan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          productId,
          name: newPlanName,
          type: newPlanType,
          quota: newPlanType === 'quota' ? parseInt(newPlanQuota) : null,
        }),
      });
      if (response.ok) {
        setNewPlanName('');
        setNewPlanType('consumption');
        setNewPlanQuota('');
        fetchProducts();
      } else {
        console.error('Failed to add plan');
      }
    } catch (error) {
      console.error('Error adding plan:', error);
    }
  };

  const handleRemoveProduct = async (productId) => {
    if (window.confirm('Are you sure you want to remove this product?')) {
      try {
        const response = await fetch(`${config.apiBaseUrl}/api/RemoveProduct`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({ productId }),
        });
        if (response.ok) {
          fetchProducts();
        } else {
          console.error('Failed to remove product');
        }
      } catch (error) {
        console.error('Error removing product:', error);
      }
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Product and Plan Management</h1>
      
      <div className="mb-4">
        <input
          type="text"
          value={newProductName}
          onChange={(e) => setNewProductName(e.target.value)}
          placeholder="New Product Name"
          className="border p-2 mr-2"
        />
        <button onClick={handleAddProduct} className="bg-blue-500 text-white p-2 rounded">
          Add New Product
        </button>
      </div>

      {products.map((product) => (
        <div key={product.id} className="border p-4 mb-4 rounded">
          <h2 className="text-xl font-bold mb-2">{product.name}</h2>
          <button
            onClick={() => handleRemoveProduct(product.id)}
            className="bg-red-500 text-white p-2 rounded mb-2"
          >
            Remove Product
          </button>
          <h3 className="text-lg font-semibold mb-2">Plans:</h3>
          <ul className="list-disc pl-5 mb-4">
            {product.plans.map((plan) => (
              <li key={plan.id}>
                {plan.name} - Type: {plan.type}
                {plan.type === 'quota' && ` (Monthly Quota: ${plan.quota} tokens)`}
              </li>
            ))}
          </ul>
          <div>
            <input
              type="text"
              value={newPlanName}
              onChange={(e) => setNewPlanName(e.target.value)}
              placeholder="New Plan Name"
              className="border p-2 mr-2"
            />
            <select
              value={newPlanType}
              onChange={(e) => setNewPlanType(e.target.value)}
              className="border p-2 mr-2"
            >
              <option value="consumption">Consumption</option>
              <option value="quota">Quota</option>
            </select>
            {newPlanType === 'quota' && (
              <input
                type="number"
                value={newPlanQuota}
                onChange={(e) => setNewPlanQuota(e.target.value)}
                placeholder="Monthly Quota"
                className="border p-2 mr-2"
              />
            )}
            <button
              onClick={() => handleAddPlan(product.id)}
              className="bg-green-500 text-white p-2 rounded"
            >
              Add New Plan
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductPlanManagement;