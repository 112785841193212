import React, { useState, useEffect, useCallback } from 'react';
import TeamColleagues from './TeamColleagues';
import InviteLinkGenerator from './InviteLinkGenerator';
import OutstandingInvites from './OutstandingInvites';
import config from '../config';
import authService from '../services/authService';

const AdminDashboard = ({ user, selectedTeam, userRole }) => {
  const [error, setError] = useState('');
  const [outstandingInvites, setOutstandingInvites] = useState([]);
  const [showAcceptedInvites, setShowAcceptedInvites] = useState(false);

  const fetchOutstandingInvites = useCallback(async () => {
    if (!selectedTeam) return;
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/GetOutstandingInvites?teamId=${selectedTeam}&includeOld=${showAcceptedInvites}`, {
        method: 'GET',
        headers: authService.getAuthHeaders(),
      });
      if (response.ok) {
        const invites = await response.json();
        setOutstandingInvites(invites);
      } else {
        if (response.status === 403) {
          setError('Only admins can see team invites');
        } else {
          setError('Failed to fetch outstanding invites');
        }        
      }
    } catch (error) {
      setError('Error fetching outstanding invites');
    }
  }, [selectedTeam, showAcceptedInvites]);

  useEffect(() => {
    fetchOutstandingInvites();
  }, [fetchOutstandingInvites]);

  const toggleShowAcceptedInvites = () => {
    setShowAcceptedInvites(!showAcceptedInvites);
  };

  const handleInviteDeleted = (deletedInviteToken) => {
    setOutstandingInvites(prevInvites => 
      prevInvites.filter(invite => invite.InviteToken !== deletedInviteToken)
    );
  };
  
  return (
    <div className="max-w-6xl mx-auto p-8">
      <h1 className="text-4xl font-bold mb-8">Admin Dashboard</h1>
      
      {error && (
        <div className="mb-4 bg-red-100 border border-red-500 text-red-700 px-4 py-3 rounded">
          {error}
        </div>
      )}
      
      {selectedTeam && (
        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Team Management</h2>
          <p>Current team: {selectedTeam}</p>
          <p>Your role: {userRole}</p>
          <TeamColleagues teamId={selectedTeam} />
          <div className="mt-4">
            <h3 className="text-xl font-bold mb-2">Invite New Member</h3>
            <InviteLinkGenerator teamId={selectedTeam} />
          </div>
          <div className="mt-4">
            <h3 className="text-xl font-bold mb-2">Outstanding Invites</h3>
            <div className="mb-2">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox"
                  checked={showAcceptedInvites}
                  onChange={toggleShowAcceptedInvites}
                />
                <span className="ml-2">Show Accepted Invites</span>
              </label>
            </div>
            <OutstandingInvites 
              invites={outstandingInvites}
              teamId={selectedTeam}
              onInviteDeleted={handleInviteDeleted}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;