import React from 'react';

const TeamSelector = ({ teams, selectedTeam, onTeamChange }) => {
  return (
    <select
      value={selectedTeam ? selectedTeam.teamId : ''}
      onChange={(e) => {
        const selected = teams.find(team => team.teamId === e.target.value);
        onTeamChange(selected);
      }}
      className="bg-white text-purple-900 border-none p-2 rounded"
    >
      <option value="">Select a team</option>
      {teams.map((team) => (
        <option key={team.teamId} value={team.teamId}>
          {team.DisplayName}
        </option>
      ))}
    </select>
  );
};

export default TeamSelector;